import { StrictMode } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';

import { App } from 'src/App';
import 'src/scss/App.scss';

import './helpers/filestackModalFix';
import './instrumentation/instrumentation';

const root = createRoot(document.getElementById('root')!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
