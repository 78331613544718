import { gql, useQuery } from '@apollo/client';
import { createContext, ReactNode } from 'react';

import { useAuth } from 'src/user';

export type ExtranetFeatures = {
  hasVideoConference: boolean;
  isVideoConferenceV1: boolean;
  hasElearning: boolean;
  hasAcademyDescription: boolean;
  hasVideocallDevelopmentFeatures: boolean;
};

type ExtranetFeaturesProviderProps = {
  children: ReactNode;
};

const EXTRANET_FEATURES_QUERY = gql`
  query ($userCode: String!) {
    extranetFeatures(userCode: $userCode) {
      hasVideoConference
      isVideoConferenceV1
      hasElearning
      hasAcademyDescription
      hasVideocallDevelopmentFeatures
    }
  }
`;

export const ExtranetFeaturesContext = createContext<ExtranetFeatures | undefined>(undefined);

/**
 * Load and provide the currently enabled features of the extranet.
 * Use the `useExtranetFeatures` hook to get the list of those features and their
 * activation state for the current user.
 */
export function ExtranetFeaturesProvider(props: ExtranetFeaturesProviderProps) {
  const { auth } = useAuth();
  const { data, loading, error } = useQuery(EXTRANET_FEATURES_QUERY, {
    variables: {
      userCode: auth?.userCode,
    },
    skip: !auth,
  });

  if (!data && loading) {
    return null;
  }

  if (error) {
    throw new Error(`Failed fetching extranet features: ${error}`);
  }

  return <ExtranetFeaturesContext.Provider value={data.extranetFeatures}>{props.children}</ExtranetFeaturesContext.Provider>;
}
