/*
 * What's this?
 * From this bug: https://www.notion.so/LMS-Bug-CV-impossible-de-s-lectionner-un-fond-d-cran-tant-que-l-utilisateur-n-est-pas-connect--13619edce8d180f6ada2e455d9b513a8?pvs=4
 * There's an "event race condition" when the filestack file picker is used
 * within a dialog. Radix's dialog listens for the pointerdown event at capture
 * so it intercepts any interaction with the filestack picker dialog.
 * What's more is that while the Radix dialog is shown, the whole body has
 * pointer-events set to none.
 *
 * This fix adds the same event listener and stops the propagation if the target
 * is within the file picker.
 * There's also a CSS rule specifically for overriding the pointer-events
 * property on the file picker dialog. It relies on a third-party internal ID,
 * so it's weak but we don't seem to have any other option.
 */

const FILESTACK_MODAL_INTERNAL_ID = '__filestack-picker';

fix_filestack_modal_inside_radix();

function fix_filestack_modal_inside_radix() {
  document.body.addEventListener(
    'pointerdown',
    event => {
      const filepickerRoot = document.getElementById(FILESTACK_MODAL_INTERNAL_ID);

      if (event.target instanceof Element && filepickerRoot && filepickerRoot.contains(event.target)) {
        event.stopImmediatePropagation();
      }
    },
    { capture: true }
  );
}
