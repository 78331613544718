import { pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const pickKeys = (obj, keys) => Object.keys(pick(obj, keys));

export const getTruthyKeys = obj => {
  const truthyKeys = Object.keys(obj).filter(key => Boolean(obj[key]));
  return pickKeys(obj, truthyKeys);
};

export const callAll =
  (...fns) =>
  (...args) => {
    fns.forEach(fn => {
      if (fn) {
        fn(...args);
      }
    });
  };

export const isMacOS = navigator.platform.toLowerCase().includes('mac');
const isIEedge = navigator.userAgent.indexOf('Edg') > -1;
const isChromium = window.chrome;
const isOpera = typeof window.opr !== 'undefined';

export const isChrome = () => {
  const winNav = window.navigator;
  const vendorName = winNav.vendor;

  return (
    isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && isOpera === false && isIEedge === false
  );
};

export const isSafari = () => !isChrome() && !isIEedge && !isOpera && window.navigator.userAgent.indexOf('Safari') > -1;

export const isFirefox = navigator.userAgent.includes('Gecko');

/**
 * Strip an object of all properties not listed.
 * Nested objects are also stripped of the same properties.
 * @param {object} target Object to strip of extraneous properties
 * @param {string[]} keptProperties Properties to keep in the target
 * @returns A semi-shallow copy of the target object stripped
 *  of all properties not listed in `keptProperties`
 */
export function keepProperties(target, keptProperties) {
  const cleanTarget = {};

  for (const property in target) {
    if (typeof target[property] === 'object') {
      const subTarget = keepProperties(target[property], keptProperties);

      if (subTarget) {
        cleanTarget[property] = subTarget;
      }
    }

    if (typeof target[property] !== 'object' && keptProperties.includes(property)) {
      cleanTarget[property] = target[property];
    }
  }

  return cleanTarget;
}

const localeSearchCollator = new Intl.Collator(undefined, { sensitivity: 'base', usage: 'search' });

/**
 * Look for a substring in a reference string.
 * The search is locale-aware, case- and accent-insensitive.
 *
 * @param {string} reference String in which to look.
 * @param {string} search String to look for.
 * @returns `true` if `search` is contained in `reference`, `false` otherwise.
 */
export function fuzzyContains(reference, search) {
  if (search.length === 0) {
    return true;
  }

  reference = reference.normalize('NFC');
  search = search.normalize('NFC');

  for (let i = 0; i + search.length <= reference.length; i++) {
    const referenceSlice = reference.slice(i, i + search.length);

    if (localeSearchCollator.compare(search, referenceSlice) === 0) {
      return true;
    }
  }

  return false;
}

/**
 * Round a decimal number to a given number of decimal places.
 * @param {number} number Decimal number to round.
 * @param {number} numberOfDecimals Number of decimal places to keep.
 * @returns A decimal number, rounded to the given number of decimal places.
 */
export function decimalRound(number, numberOfDecimals = 0) {
  const factor = 10 ** numberOfDecimals;

  return Math.round(number * factor) / factor;
}

/**
 * Check if two values are deeply equal.
 * @param {*} x First value to compare.
 * @param {*} y Second value to compare.
 * @returns {boolean} True if the values are deeply equal, false otherwise.
 */
export function isEqual(x, y) {
  const objectKeys = Object.keys,
    xType = typeof x,
    yType = typeof y;
  return x && y && xType === 'object' && xType === yType
    ? objectKeys(x).length === objectKeys(y).length && objectKeys(x).every(key => isEqual(x[key], y[key]))
    : x === y;
}

/**
 * Compares two strings lexicographically.
 *
 * @param {string} a - The first string to compare.
 * @param {string} b - The second string to compare.
 * @returns {number} - A number representing the comparison result:
 *                     - Negative if a < b
 *                     - Positive if a > b
 *                     - Zero if a === b
 */
export function compareStrings(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function generateUUID() {
  if (typeof crypto !== 'undefined') {
    return crypto.randomUUID();
  }

  return uuidv4();
}

/**
 * Create an array of numbers from 0 to size - 1.
 * @param {number} size - The size of the array to create.
 * @returns {number[]} An array of numbers from 0 to size - 1.
 */
export function range(size) {
  return [...new Array(size).keys()];
}

/**
 * Get the first grapheme of a string.
 *
 * This is preferable to `string.charAt(0)` because some characters may use
 * multiple code units (such as emojis.)
 * @param {string} string Target.
 * @returns {string} The first grapheme.
 */
export function getFirstGrapheme(string) {
  return String.fromCodePoint(string.codePointAt(0));
}
